import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';

import {IonRouterOutlet, NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar, Style} from '@capacitor/status-bar';
import {FcmService} from './services/fcm.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
// @ts-ignore
import {Stripe} from '@capacitor-community/stripe';
import {StripeNativeService} from './services/stripe/stripe.service';
import {Badge} from '@awesome-cordova-plugins/badge/ngx';
// import {FCM} from '@awesome-cordova-plugins/fcm/ngx';

const stripe_key = 'pk_live_51JXbGGAxycoUsFxGka4JnknQAHYd06smUas8hThySaWhxMNPz1QNif2IBJG8SxuqQP7BQnmsMXo2bMCdx0UXD9PF005HU9rjGn';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  currentUser: any;
  loggedIn = false;
  notifications;

  constructor(private platform: Platform,
              private splashScreen: SplashScreen,
              private fcmService: FcmService,
              public router: Router,
              private badge: Badge,
              private navCtrl: NavController, private afs: AngularFirestore,
              private stripeNative: StripeNativeService,
              // private fcm: FCM
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  ngOnInit() {
    // PushNotifications.requestPermissions().then(result => {
    //   if (result.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //   }
    // });

    // PushNotifications.addListener(
    //   'registration',
    //   (token: Token) => {
    //     console.log('My token: ' + JSON.stringify(token));
    //     localStorage.setItem('tokenTapApp', (JSON.stringify(token.value)));
    //   });

    // PushNotifications.addListener('registrationError', (error: any) => {
    //   console.log('Error on registration: ' + JSON.stringify(error));
    // });

    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     console.log('Push received: ' + JSON.stringify(notification));
    //   },
    // );

    // PushNotifications.addListener(
    //   'pushNotificationActionPerformed',
    //   (notification: ActionPerformed) => {
    //     console.log('Push action performed: ' + JSON.stringify(notification));
    //   },
    // );


    // this.fcm.onNotification().subscribe(data => {
    //   //Here we can get the data using data.actionType and data.payload and use it accordingly
    //   if (data.wasTapped) {
    //     console.log('Received in background');
    //   } else {
    //     console.log('Received in foreground');
    //   }
    // });
    // this.fcm.onTokenRefresh().subscribe(token => {
    //   // Register your new token in your back-end if you want
    //   // backend.registerToken(token);
    // });
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (this.router.url === '/welcome-login-signup' || this.router.url === '/tabs/feed' || this.router.url === 'welcome-tapnetwork') {
          navigator['app'].exitApp();
        } else {
          window.history.back();
        }
      });
    });
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.fcmService.initPush();

      // this.statusBar.styleDefault();
      // this.fcm.subscribeToTopic('user_data').then(token => {
      //   console.log('My Topic :' + token);
      // });
      // ;

      // this.fcm.getToken().then(token => {
      //   //backend.registerToken(token);
      //   console.log(token);
      // });

      // this.fcm.onNotification().subscribe(data => {
      //   if (data.wasTapped) {
      //     console.log("Received in background");
      //   } else {
      //     console.log("Received in foreground");
      //   }
      //   ;
      // });

      // this.fcm.onTokenRefresh().subscribe(token => {
      //   // backend.registerToken(token);
      // });

      // this.fcm.hasPermission().then(hasPermission => {
      //   if (hasPermission) {
      //     // console.log("Has permission!");
      //   }
      // });

      // this.fcm.clearAllNotifications();

      // this.fcm.unsubscribeFromTopic('user_data');
      // this.splashScreen.hide();
      // const showStatusBar = async () => {
      //   await StatusBar.show();
      // };


      // const opt: StatusBarBackgroundColorOptions = {
      //   color: '#000000'
      // }
      // const setStatusBarStyleColor = async () => {
      //   await StatusBar.setBackgroundColor(opt);
      // };
      const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({style: Style.Dark});
      };
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({style: Style.Dark});
        await StatusBar.setBackgroundColor({
          color: '#b70710'
        });
      };
      // StatusBar.setBackgroundColor({
      //   color: '#b70710'
      // });
      setTimeout(() => {
        document.getElementById("custom-overlay").style.display = "none";
      }, 4000);


      // Trigger the push setup
      // this.fcmService.initPush();
      if (localStorage.getItem('currentUser') != null && localStorage.getItem('currentUser') != undefined && localStorage.getItem('currentUser') != '' && localStorage.getItem('currentUser') != "undefined") {
        const user = localStorage.getItem('currentUser');
        this.currentUser = JSON.parse(user);
        this.getNumNotifications();
      }


      //Check if user is already logged in
      if (this.currentUser && this.currentUser.uid) {
        //Setup subscribe so local storage is updated anytime the current user's data is changed
        const userDetails = this.afs
          .collection('user_data')
          .doc(this.currentUser.uid)
        let ref = userDetails.valueChanges()
        let sub = ref.subscribe((val) => {
          localStorage.setItem("currentUser", JSON.stringify(val));

          //Navigate to the home page and set loggedIn to true
          if (!this.loggedIn) {
            if (JSON.parse(localStorage.getItem('currentUser')).darkMode) {
              document.body.setAttribute('color-theme', 'dark');
            } else {
              document.body.setAttribute('color-theme', 'light');
            }
            if (JSON.parse(localStorage.getItem('currentUser')).lightDarkMode != "") {
              document.body.setAttribute('color-theme', JSON.parse(localStorage.getItem('currentUser')).lightDarkMode)
            }
            if (JSON.parse(localStorage.getItem('currentUser')).appTheme) {
              this.setAppTheme(JSON.parse(localStorage.getItem('currentUser')).appTheme);
            }
            this.loggedIn = true;
            console.log(this.router.url);
            const url = this.router.url;
            if (!url || url === '/welcome-login-signup' || url === '/welcome-tapnetwork') {
              this.navCtrl.navigateForward('tabs/feed');
            }
          }
        });
      } else {

        //
        // this.navCtrl.navigateForward('login');
        // this.navCtrl.navigateForward('welcome-login-signup');
      }
      // Stripe initialize
      await Stripe.initialize({
        publishableKey: stripe_key,
      });
      this.stripeNative.initilization();
    });
  }


  setAppTheme(appTheme) {
    let primary = "#B70710";
    let shade = "#a1060e"
    let tint = "#be2028";

    switch (appTheme) {
      case "black": {
        primary = "#000000";
        shade = "#000000"
        tint = "#000000";
        break;
      }
      case "red": {
        primary = "#B70710";
        shade = "#a1060e"
        tint = "#be2028";
        break;
      }
      case "orange": {
        primary = "#FB5607";
        shade = "#dd4c06"
        tint = "#fb6720";
        break;
      }
      case "blue": {
        primary = "#216CA6";
        shade = "#1d5f92"
        tint = "#377baf";
        break;
      }
      case "pink": {
        primary = "#FF006E";
        shade = "#e00061"
        tint = "#ff1a7d";
        break;
      }
      case "yellow": {
        primary = "#FFBE0B";
        shade = "#e0a70a"
        tint = "#ffc523";
        break;
      }
      case "purple": {
        primary = "#8338EC";
        shade = "#7331d0"
        tint = "#8f4cee";
        break;
      }
      case "white": {
        primary = "#FFFFFF";
        shade = "#FFFFFF"
        tint = "#FFFFFF";
        break;
      }
    }

    document.documentElement.style.setProperty("--ion-color-primary", primary);
    document.documentElement.style.setProperty("--ion-color-primary-rgb", primary);
    document.documentElement.style.setProperty("--ion-color-primary-shade", shade);
    document.documentElement.style.setProperty("--ion-color-primary-tint", tint);
  }

  async getNumNotifications() {
    const notifData = this.afs
      .collection('user_data')
      .doc(this.currentUser.uid)
      .collection('notifications', ref => ref.where('viewed', '==', false));
    const reference = notifData.valueChanges();
    const sub = reference.subscribe(notifs => {
      this.notifications = JSON.parse(JSON.stringify(notifs)).length;
      console.log(this.notifications);
      this.badge.set(this.notifications);
      document.title = '(' + this.notifications + ')' + ' Tap';
    });
  }
}
