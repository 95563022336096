import {Injectable} from '@angular/core';
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from '@capacitor/push-notifications';
import {Router} from '@angular/router';
// import {FCM} from "@awesome-cordova-plugins/fcm/ngx";
import {Observable, Subject} from "rxjs";
import { Capacitor } from '@capacitor/core';
import {AngularFirestore} from '@angular/fire/firestore';
// const { PushNotifications } = Plugins;
import * as firebase from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class FcmService {
  token = "";
  private subjectPageStatus = new Subject<any>();

  constructor(private router: Router, 
    private afs: AngularFirestore,
    // private fcm: FCM
    ) {

  }

  public initPush() {
    if (Capacitor.platform !== 'web') {
      console.log("Sultan NAWAZ ye iphone hai")
      this.registerPush();
     }
  
  }

  private registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      console.log("Permissions", permission)
      if (permission.receive === 'granted') {
        console.log("Granted Here")
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.log('My token: ' + JSON.stringify(token));
        console.log('My token : CV : ', token.value);
        this.token = token.value;
        console.log("Tokenx", token.value);
        localStorage.setItem('tokenTapApp', token.value);
        // if(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== 'undefined'){
        //   const tempUser = JSON.parse(localStorage.getItem('currentUser'));
        //   console.log("User Iphone : ",tempUser);
        //   if(tempUser){
        //     console.log(tempUser.uid)
        //     this.updateToken(tempUser.uid, token.value);
        //   }
        // }
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error Sulta mn hai : ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // Play the default notification sound of the device
        const sound = new Audio('default');
        sound.play();
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const data: any = notification;
        console.log('Action performed: ' + JSON.stringify(notification));
        this.router.navigate(['tabs/notifications']);
        if (data.senderId) {
          let id = data.data.senderId;
          this.router.navigate(['tabs/notifications']);
          // this.router.navigateByUrl(`/home/${data.detailsId}`);
        }
      }
    );

    // this.fcm.onNotification().subscribe(data => {
    //   //Here we can get the data using data.actionType and data.payload and use it accordingly
    //   if (data.wasTapped) {
    //     console.log('Received in background');
    //   } else {
    //     console.log('Received in foreground');
    //   }
    // });
  }


  sendPageStatus(message: any) {
    this.subjectPageStatus.next(message);
  }

  onMessagePageStatus(): Observable<any> {
    return this.subjectPageStatus.asObservable();
  }

  clearMessages() {
    this.subjectPageStatus.next(false);
  }

  async updateToken(currentUserId, token){
   await this.afs.collection('user_data')
   .doc(currentUserId)
   .update({
      device_token: token,
   })
   .then(() => {
      console.log('Device token updated successfully');
   })
   .catch(error => {
      console.error('Error updating device token:', error);
   });


   firebase.firestore().collection('user_data')
   .doc(currentUserId)
   .update({
      device_token: token,
   })
   .then(() => {
      console.log('Device token updated successfully');
   })
   .catch(error => {
      console.error('Error updating device token:', error);
   });
  }

}
