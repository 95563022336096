import {EmailComposer} from '@ionic-native/email-composer/ngx';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from 'src/environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {PostService} from './services/post.service';
import {UserService} from './services/user.service';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {ChatService} from './services/chat.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Network} from '@ionic-native/network/ngx';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';

import {ReactiveFormsModule} from '@angular/forms';
import {Base64} from '@ionic-native/base64/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {MediaCapture} from '@ionic-native/media-capture/ngx';
import {File} from '@ionic-native/file/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {AutoFocus} from './shared/directives/autofocus.directive';
import {ReportAccountService} from 'src/app/services/report-account.service';
import {IonicStorageModule} from '@ionic/storage-angular';
import {UniqueDeviceID} from '@ionic-native/unique-device-id/ngx';
import {Keyboard} from '@awesome-cordova-plugins/keyboard/ngx';
import {StripeNativeService} from './services/stripe/stripe.service';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Media} from '@ionic-native/media/ngx';
import {Badge} from '@awesome-cordova-plugins/badge/ngx';
import {TokenInterceptorService} from './interceptors/token-interceptor.service';
// import {FCM} from '@awesome-cordova-plugins/fcm/ngx';
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
// import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import {Ndef, NFC} from '@awesome-cordova-plugins/nfc/ngx';

// @ts-ignore
@NgModule({
  declarations: [AppComponent, AutoFocus],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    IonicStorageModule.forRoot(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    HttpClientModule,
    ReactiveFormsModule,
    // NgxIntlTelInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, PostService, StripeNativeService, 
    // FCM,
    UserService, ReportAccountService, StatusBar, WebView, EmailComposer, Base64, FilePath, Keyboard,
    Camera, MediaCapture, File, SplashScreen, ChatService, InAppBrowser, Network, UniqueDeviceID, Media, Badge,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    Diagnostic,
    NFC,
    Ndef,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
