import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // welcome-login-signup
    redirectTo: 'welcome-tapnetwork',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'welcome-login-signup',
    loadChildren: () => import('./pages/welcome-login-signup/welcome-login-signup.module').then(m => m.WelcomeLoginSignupPageModule)
  },
  {
    path: 'welcome-tapnetwork',
    loadChildren: () => import('./pages/welcome-tapnetwork/welcome-tapnetwork.module').then(m => m.WelcomeTapnetworkPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'confirmation-code',
    loadChildren: () => import('./pages/confirmation-code/confirmation-code.module').then(m => m.ConfirmationCodePageModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./pages/confirm/confirm.module').then(m => m.ConfirmPageModule)
  },
  {
    path: 'sent-money-transections',
    loadChildren: () => import('./pages/sent-money-transections/sent-money-transections.module').then(m => m.SentMoneyTransectionsPageModule)
  },
  {
    path: 'send-money',
    loadChildren: () => import('./pages/send-money/send-money.module').then(m => m.SendMoneyPageModule)
  },
  {
    path: 'explore',
    loadChildren: () => import('./pages/explore/explore.module').then(m => m.ExplorePageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'signup-personal-information',
    loadChildren: () => import('./pages/signup-personal-information/signup-personal-information.module').then(m => m.SignupPersonalInformationPageModule)
  },
  {
    path: 'new-referral-code',
    loadChildren: () => import('./pages/new-referral-code-new/new-referral-code-new-routing.module').then(m => m.NewReferralCodeNewPageRoutingModule)
  },
  {
    path: 'signup-professional',
    loadChildren: () => import('./pages/signup-professional/signup-professional.module').then(m => m.SignupProfessionalPageModule)
  },
  {
    path: 'update-resume',
    loadChildren: () => import('./pages/update-resume/update-resume.module').then(m => m.UpdateResumePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'link-socials',
    loadChildren: () => import('./pages/link-socials/link-socials.module').then(m => m.LinkSocialsPageModule)
  },
  {
    path: 'make-rating',
    loadChildren: () => import('./pages/make-rating/make-rating.module').then(m => m.MakeRatingPageModule)
  },
  {
    path: 'display-ratings',
    loadChildren: () => import('./pages/display-ratings/display-ratings.module').then(m => m.DisplayRatingsPageModule)
  },
  {
    path: 'current-ratings',
    loadChildren: () => import('./pages/current-ratings/current-ratings.module').then(m => m.CurrentRatingsPageModule)
  },
  {
    path: 'external-resume',
    loadChildren: () => import('./pages/external-resume/external-resume.module').then(m => m.ExternalResumePageModule)
  },
  {
    path: 'follower-resume',
    loadChildren: () => import('./pages/follower-resume/follower-resume.module').then(m => m.FollowerResumePageModule)
  },
  {
    path: 'help-form',
    loadChildren: () => import('./pages/help-form/help-form.module').then(m => m.HelpFormPageModule)
  },
  {
    path: 'change-username',
    loadChildren: () => import('./pages/change-username/change-username.module').then(m => m.ChangeUsernamePageModule)
  },
  {
    path: 'make-payment',
    loadChildren: () => import('./pages/make-payment/make-payment.module').then(m => m.MakePaymentPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'view-social',
    loadChildren: () => import('./pages/view-social/view-social.module').then(m => m.ViewSocialPageModule)
  },
  {
    path: 'previously-liked-posts',
    loadChildren: () => import('./pages/previously-liked-posts/previously-liked-posts.module').then(m => m.PreviouslyLikedPostsPageModule)
  },
  {
    path: 'user-feed',
    loadChildren: () => import('./pages/user-feed/user-feed.module').then(m => m.UserFeedPageModule)
  },
  {
    path: 'archived-posts',
    loadChildren: () => import('./pages/archived-posts/archived-posts.module').then(m => m.ArchivedPostsPageModule)
  },
  {
    path: 'single-post',
    loadChildren: () => import('./pages/single-post/single-post.module').then(m => m.SinglePostPageModule)
  },
  {
    path: 'add-service',
    loadChildren: () => import('./pages/add-service/add-service.module').then(m => m.AddServicePageModule)
  },
  {
    path: 'view-service',
    loadChildren: () => import('./pages/view-service/view-service.module').then(m => m.ViewServicePageModule)
  },
  {
    path: 'inquiry-chat',
    loadChildren: () => import('./pages/new-messages/inquiry-chat/inquiry-chat.module').then(m => m.InquiryChatPageModule)
  },
  {
    path: 'general-chat',
    loadChildren: () => import('./pages/new-messages/general-chat/general-chat.module').then(m => m.GeneralChatPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/new-messages/messages/messages.module').then(m => m.MessagesPageModule)
  },
  {
    path: 'archived-chats',
    loadChildren: () => import('./pages/archived-chats/archived-chats.module').then(m => m.ArchivedChatsPageModule)
  },
  {
    path: 'archived-inquiries',
    loadChildren: () => import('./pages/archived-inquiries/archived-inquiries.module').then(m => m.ArchivedInquiriesPageModule)
  },
  {
    path: 'privacy-security',
    loadChildren: () => import('./pages/privacy-security/privacy-security.module').then(m => m.PrivacySecurityPageModule)
  },
  {
    path: 'post-security',
    loadChildren: () => import('./pages/post-security/post-security.module').then(m => m.PostSecurityPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialPageModule)
  },
  {
    path: 'user-video-feed',
    loadChildren: () => import('./pages/user-video-feed/user-video-feed.module').then(m => m.UserVideoFeedPageModule)
  },
  {
    path: 'single-video',
    loadChildren: () => import('./pages/single-video/single-video.module').then(m => m.SingleVideoPageModule)
  },
  {
    path: 'tutorial-services',
    loadChildren: () => import('./pages/tutorial-services/tutorial-services.module').then(m => m.TutorialServicesPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./pages/tutorials/tutorials.module').then(m => m.TutorialsPageModule)
  },
  {
    path: 'single-rating',
    loadChildren: () => import('./pages/single-rating/single-rating.module').then(m => m.SingleRatingPageModule)
  },
  {
    path: 'edit-series',
    loadChildren: () => import('./pages/edit-series/edit-series.module').then(m => m.EditSeriesPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then(m => m.ReportPageModule)
  },
  {
    path: 'user-guidelines',
    loadChildren: () => import('./pages/user-guidelines/user-guidelines.module').then(m => m.UserGuidelinesPageModule)
  },
  {
    path: 'blocked-users',
    loadChildren: () => import('./pages/blocked-users/blocked-users.module').then(m => m.BlockedUsersPageModule)
  },
  {
    path: 'send-offer',
    loadChildren: () => import('./pages/send-offer/send-offer.module').then(m => m.SendOfferPageModule)
  },
  {
    path: 'view-offer',
    loadChildren: () => import('./pages/view-offer/view-offer.module').then(m => m.ViewOfferPageModule)
  },
  {
    path: 'inquiry-checkout',
    loadChildren: () => import('./pages/inquiry-checkout/inquiry-checkout.module').then(m => m.InquiryCheckoutPageModule)
  },
  {
    path: 'deliver-order',
    loadChildren: () => import('./pages/deliver-order/deliver-order.module').then(m => m.DeliverOrderPageModule)
  },
  {
    path: 'previous-offers',
    loadChildren: () => import('./pages/previous-offers/previous-offers.module').then(m => m.PreviousOffersPageModule)
  },

  {
    path: 'tiktok-home',
    loadChildren: () => import('./pages/tiktok-home/tiktok-home.module').then(m => m.TiktokHomePageModule)
  },
  {
    path: 'post-info',
    loadChildren: () => import('./pages/post-info/post-info.module').then(m => m.PostInfoPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then(m => m.ReportPageModule)
  },
  {
    path: 'share-to-message',
    loadChildren: () => import('./pages/share-to-message/share-to-message.module').then(m => m.ShareToMessagePageModule)
  },
  {
    path: 'search-service',
    loadChildren: () => import('./pages/search-service/search-service.module').then(m => m.SearchServicePageModule)
  },
  {
    path: 'view-service',
    loadChildren: () => import('./pages/view-service/view-service.module').then(m => m.ViewServicePageModule)
  },
  {
    path: 'feed-filter',
    loadChildren: () => import('./pages/feed-filter/feed-filter.module').then(m => m.FeedFilterPageModule)
  },
  {
    path: 'visual-settings',
    loadChildren: () => import('./pages/visual-settings/visual-settings.module').then(m => m.VisualSettingsPageModule)
  },
  {
    path: 'new-message',
    loadChildren: () => import('./pages/new-message/new-message.module').then(m => m.NewMessagePageModule)
  },
  {
    path: 'login-user-list',
    loadChildren: () => import('./pages/login-user-list/login-user-list.module').then(m => m.LoginUserListPageModule)
  },
  {
    path: 'switch-add-account',
    loadChildren: () => import('./pages/switch-add-account/switch-add-account.module').then(m => m.SwitchAddAccountPageModule)
  },
  {
    path: 'add-profile-link',
    loadChildren: () => import('./pages/add-profile-link/add-profile-link.module').then(m => m.AddProfileLinkPageModule)
  },
  {
    path: 'group-chat',
    loadChildren: () => import('./pages/new-messages/group-chat/group-chat.module').then(m => m.GroupChatPageModule)
  },
  {
    path: 'group-chat-settings',
    loadChildren: () => import('./pages/new-messages/group-chat-settings/group-chat-settings.module').then(m => m.GroupChatSettingsPageModule)
  },

  // {
  //   path: 'profile-web-user',
  //   loadChildren: () => import('./pages/profile-web-user/profile-web-user.module').then( m => m.ProfileWebUserPageModule)
  // },
  {
    path: 'referral-code',
    loadChildren: () => import('./pages/referral-code/referral-code.module').then(m => m.ReferralCodePageModule)
  },
  {
    path: 'referral-leaderboard',
    loadChildren: () => import('./pages/referral-leaderboard/referral-leaderboard.module').then(m => m.ReferralLeaderboardPageModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./pages/account-settings/account-settings.module').then(m => m.AccountSettingsPageModule)
  },
  {
    path: 'edit-post',
    loadChildren: () => import('./pages/edit-post/edit-post.module').then(m => m.EditPostPageModule)
  },
  {
    path: 'links',
    loadChildren: () => import('./pages/links/links.module').then(m => m.LinksPageModule)
  },
  {
    path: 'family-group',
    loadChildren: () => import('./pages/family-group/family-group.module').then(m => m.FamilyGroupPageModule)
  },
  {
    path: 'dialog-report-post-options',
    loadChildren: () => import('./pages/dialog-report-post-options/dialog-report-post-options.module').then(m => m.DialogReportPostOptionsPageModule)
  },
  {
    path: 'dialog-report-post-confirm',
    loadChildren: () => import('./pages/dialog-report-post-confirm/dialog-report-post-confirm.module').then(m => m.DialogReportPostConfirmPageModule)
  },
  {
    path: 'dialog-block-user',
    loadChildren: () => import('./pages/dialog-block-user/dialog-block-user.module').then(m => m.DialogBlockUserPageModule)
  },
  {
    path: 'dialog-block-user-confirm',
    loadChildren: () => import('./pages/dialog-block-user-confirm/dialog-block-user-confirm.module').then(m => m.DialogBlockUserConfirmPageModule)
  },
  {
    path: 'archived-messages',
    loadChildren: () => import('./pages/archived-messages/archived-messages.module').then(m => m.ArchivedMessagesPageModule)
  },
  {
    path: 'image-gallery',
    loadChildren: () => import('./pages/image-gallery/image-gallery.module').then(m => m.ImageGalleryPageModule)
  },
  {
    path: 'other-user-profile-popup',
    loadChildren: () => import('./pages/other-user-profile-popup/other-user-profile-popup.module').then(m => m.OtherUserProfilePopupPageModule)
  },
  {
    path: 'current-user-profile-popup',
    loadChildren: () => import('./pages/current-user-profile-popup/current-user-profile-popup.module').then(m => m.CurrentUserProfilePopupPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./tapcomponents/users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: ':username',
    // redirectTo: 'profile-web-user',
    loadChildren: () => import('./pages/profile-web-user/profile-web-user.module').then(m => m.ProfileWebUserPageModule)
  },
  {
    path: 'new-referral-code',
    loadChildren: () => import('./pages/new-referral-code/new-referral-code.module').then(m => m.NewReferralCodePageModule)
  },
  {
    path: 'new-referral-code-new',
    loadChildren: () => import('./pages/new-referral-code-new/new-referral-code-new.module').then(m => m.NewReferralCodeNewPageModule)
  },
  {
    path: 'link-socials-dialogue',
    loadChildren: () => import('./pages/link-socials-dialogue/link-socials-dialogue.module').then(m => m.LinkSocialsDialoguePageModule)
  },

];

@NgModule({
  imports: [
    // useHash: true,
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
