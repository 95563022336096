import { PostService } from './post.service';
import { ShareService } from './share.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportAccountService {

  whoReported: string; //Current User's id
  reportedUser: string; //User's id
  reportedUserName: string; //User's username
  reason: string; //Reason for reporting

  constructor(private afs: AngularFirestore) { }

  reportAccount(reason){

    let reportString = this.reportedUser + reason;
    let createdAt: Date[] = [];
    createdAt[0] = new Date();
    let whoReported: string[] = [];
    whoReported[0] = this.whoReported;
    this.reason = reason;

    let report = {
      whoReported: whoReported,
      reportedUser: this.reportedUser,
      reason: this.reason,
      createdAt: createdAt,
      numReports: 1
    }
    console.log(report);

    this.afs
      .collection('user_reports')
      .doc(reportString)
      .get()
      .toPromise()
      .then(doc => {
        if (doc.exists) {
          let updateReport: any = doc.data();
          updateReport.createdAt[updateReport.createdAt.length] = new Date();
          updateReport.whoReported[updateReport.whoReported.length] = this.whoReported;
          updateReport.numReports++;

          this.afs
            .collection('user_reports')
            .doc(reportString)
            .update({
              createdAt: updateReport.createdAt,
              numReports: updateReport.numReports,
              whoReported: updateReport.whoReported
            })
        }
        else {
          this.afs
            .collection('user_reports')
            .doc(reportString)
            .set(report)
        }
      })

  }

  setReason(value){
    this.reason = value;
  }

  setWhoReported(value){
    this.whoReported = value;
  }

  setReportedUser(value){
    this.reportedUser = value;
  }

  setReportedUserName(value){
    this.reportedUserName = value;
  }

  getReportedUserName(){
    return this.reportedUserName;
  }

  getReason(){
    return this.reason;
  }
}
