import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {
  ApplePayEventsEnum,
  GooglePayEventsEnum,
  PaymentFlowEventsEnum,
  PaymentSheetEventsEnum,
  Stripe
} from '@capacitor-community/stripe';
import {LoadingController} from '@ionic/angular';
import {first} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ServiceMessagesService} from '../service-messages.service';
import {ShareService} from '../share.service';

const stripe_client_secret = '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';

@Injectable({
  providedIn: 'root'
})
export class StripeNativeService {
  processSheet: 'willReady' | 'Ready' = 'willReady';
  processFlow: 'willReady' | 'Ready' | 'canConfirm' = 'willReady';
  processApplePay: 'willReady' | 'Ready' = 'willReady';
  processGooglePay: 'willReady' | 'Ready' = 'willReady';
  isApplePayAvailable = false;
  isGooglePayAvailable = false;
  customer_email: any;
  customer_stripe_id: any;
  account_stripe_id: any;
  response: any = {
    error: false,
    paymentIntent: {
      status: ''
    },
    createPaymentIntent: ''
  }
  // dmId: any;
  // offer: any;
  // index: number;
  // otherUser: any;
  // secretKey: any;
  // total: any;
  // tapFee: any;
  // previousPage: any;
  // pi: any;

  // currentUser: any;
  // response: any;

  canSendPayment: boolean = true;

  constructor(
    private http: HttpClient,
    private loadingController: LoadingController,
    private shareService: ShareService,
    private messageService: ServiceMessagesService,
    private afs: AngularFirestore) {
    this.initilization();
  }

  initilization() {
    Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
      this.processSheet = 'Ready';
      console.log('PaymentSheetEventsEnum.Loaded');
    });

    Stripe.addListener(PaymentSheetEventsEnum.FailedToLoad, () => {
      console.log('PaymentSheetEventsEnum.FailedToLoad');
    });

    Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
      this.processSheet = 'willReady';
      console.log('PaymentSheetEventsEnum.Completed');
    });

    Stripe.addListener(PaymentSheetEventsEnum.Canceled, () => {
      this.processSheet = 'willReady';
      console.log('PaymentSheetEventsEnum.Canceled');
    });

    Stripe.addListener(PaymentSheetEventsEnum.Failed, () => {
      this.processSheet = 'willReady';
      console.log('PaymentSheetEventsEnum.Failed');
    });

    /** ------------------------------------------------------------------- **/

    Stripe.addListener(PaymentFlowEventsEnum.Loaded, () => {
      this.processFlow = 'Ready';
      console.log('PaymentFlowEventsEnum.Loaded');
    });

    Stripe.addListener(PaymentFlowEventsEnum.FailedToLoad, () => {
      console.log('PaymentFlowEventsEnum.FailedToLoad');
    });

    Stripe.addListener(PaymentFlowEventsEnum.Completed, () => {
      this.processFlow = 'willReady';
      console.log('PaymentFlowEventsEnum.Completed');
    });

    Stripe.addListener(PaymentFlowEventsEnum.Canceled, () => {
      this.processFlow = 'willReady';
      console.log('PaymentFlowEventsEnum.Canceled');
    });

    Stripe.addListener(PaymentFlowEventsEnum.Failed, () => {
      this.processFlow = 'willReady';
      console.log('PaymentFlowEventsEnum.Failed');
    });

    Stripe.addListener(PaymentFlowEventsEnum.Created, (info) => {
      console.log(info);
      this.processFlow = 'canConfirm';
    });

    /** ------------------------------------------------------------------- **/

    Stripe.addListener(ApplePayEventsEnum.Loaded, () => {
      this.processApplePay = 'Ready';
      console.log('ApplePayEventsEnum.Loaded');
    });

    Stripe.addListener(ApplePayEventsEnum.FailedToLoad, () => {
      console.log('ApplePayEventsEnum.FailedToLoad');
    });

    Stripe.addListener(ApplePayEventsEnum.Completed, () => {
      this.processApplePay = 'willReady';
      console.log('ApplePayEventsEnum.Completed');
    });

    Stripe.addListener(ApplePayEventsEnum.Canceled, () => {
      this.processApplePay = 'willReady';
      console.log('ApplePayEventsEnum.Canceled');
    });

    Stripe.addListener(ApplePayEventsEnum.Failed, () => {
      this.processApplePay = 'willReady';
      console.log('ApplePayEventsEnum.Failed');
    });

    Stripe.isApplePayAvailable().then(() => this.isApplePayAvailable = true);

    /** ------------------------------------------------------------------- **/

    Stripe.addListener(GooglePayEventsEnum.Loaded, () => {
      this.processGooglePay = 'Ready';
      console.log('ApplePayEventsEnum.Loaded');
    });

    Stripe.addListener(GooglePayEventsEnum.FailedToLoad, () => {
      console.log('ApplePayEventsEnum.FailedToLoad');
    });

    Stripe.addListener(GooglePayEventsEnum.Completed, () => {
      this.processGooglePay = 'willReady';
      console.log('ApplePayEventsEnum.Completed');
    });

    Stripe.addListener(GooglePayEventsEnum.Canceled, () => {
      this.processGooglePay = 'willReady';
      console.log('ApplePayEventsEnum.Canceled');
    });

    Stripe.addListener(GooglePayEventsEnum.Failed, () => {
      this.processGooglePay = 'willReady';
      console.log('ApplePayEventsEnum.Failed');
    });

    Stripe.isGooglePayAvailable().then(() => this.isGooglePayAvailable = true);
  }

  async ApplePay(amount) {
    amount *= 100;
    amount = Math.trunc(amount);
    // Check to be able to use Apple Pay on device
    const isAvailable = Stripe.isApplePayAvailable().catch(() => undefined);

    if (isAvailable === undefined) {
      // disable to use Google Pay
      return;
    }

    // be able to get event of Apple Pay
    Stripe.addListener(ApplePayEventsEnum.Completed, () => {
      console.log('ApplePayEventsEnum.Completed');
    });

    // Connect to your backend endpoint, and get paymentIntent.
    const {paymentIntent} = await this.http.post<{
      paymentIntent: string;
    }>(environment.api + 'payment-sheet', {
      user_id: this.customer_stripe_id,
      amount: amount,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);
    // console.log(paymentIntent)
    // Prepare Apple Pay
    await Stripe.createApplePay({
      paymentIntentClientSecret: paymentIntent,
      paymentSummaryItems: [{
        label: 'Service payment',
        amount: amount / 100
      }],
      merchantIdentifier: 'merchant.com.tapnetworkapp.tap',
      countryCode: 'US',
      currency: 'USD',
    });
    this.response.createPaymentIntent = paymentIntent;
    // Present Apple Pay
    const result = await Stripe.presentApplePay();

    if (result.paymentResult === ApplePayEventsEnum.Completed) {
      // Happy path
      this.response.paymentIntent.status = 'succeeded';
      this.response.error = false;
    } else {
      this.response.error = true;
    }
    return this.response;
  }

  async GooglePay(amount) {
    amount *= 100;
    amount = Math.trunc(amount);
    // Check to be able to use Google Pay on device
    const isAvailable = Stripe.isGooglePayAvailable().catch(() => undefined);
    if (isAvailable === undefined) {
      // disable to use Google Pay
      return;
    }

    Stripe.addListener(GooglePayEventsEnum.Completed, () => {
      console.log('GooglePayEventsEnum.Completed');
    });

    // Connect to your backend endpoint, and get paymentIntent.
    const {paymentIntent} = await this.http.post<{
      paymentIntent: string;
    }>(environment.api + 'payment-sheet', {
      user_id: this.customer_stripe_id,
      amount: amount,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);

    // Prepare Google Pay
    await Stripe.createGooglePay({
      paymentIntentClientSecret: paymentIntent,
    });
    this.response.createPaymentIntent = paymentIntent;
    // Present Google Pay
    const result = await Stripe.presentGooglePay();
    if (result.paymentResult === GooglePayEventsEnum.Completed) {
      // Happy path
      this.response.paymentIntent.status = 'succeeded';
      this.response.error = false;
    } else {
      this.response.error = true;
    }
    return this.response;
  }

  async createCustomer(user) {
    const {paymentIntent, ephemeralKey, customer} = await this.http.post<{
      paymentIntent: string;
      ephemeralKey: string;
      customer: string;
    }>(environment.api + 'create-customer', {name: user.userName, email: user.email}).pipe(first()).toPromise(Promise);
    return {paymentIntent, ephemeralKey, customer};
  }

  async createPaymentSheet(amount, currency) {
    const {paymentIntent, ephemeralKey, customer} = await this.http.post<{
      paymentIntent: string;
      ephemeralKey: string;
      customer: string;
    }>(environment.api + 'retrieve-account', {email: this.customer_email}).pipe(first()).toPromise(Promise);
    console.log(paymentIntent, ephemeralKey, customer);
    await Stripe.createPaymentSheet({
      paymentIntentClientSecret: paymentIntent,
      customerEphemeralKeySecret: ephemeralKey,
      customerId: customer,
      merchantDisplayName: 'rdlabo',
    });
  }

  async paymentSheetHttp(amount, currency) {
    amount *= 100;
    amount = Math.trunc(amount);
    console.log(this.customer_stripe_id);
    // Connect to your backend endpoint, and get paymentIntent.
    const {paymentIntent} = await this.http.post<{
      paymentIntent: string;
    }>(environment.api + 'payment-sheet', {
      user_id: this.customer_stripe_id,
      amount: amount,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);
    return {
      paymentIntent: paymentIntent,
      client_secret: this.customer_stripe_id
    };
  }

  async presentPaymentSheet() {
    console.log(this.customer_email, this.customer_stripe_id)
    await this.createPaymentSheet(1000, 'USD');
    Stripe.presentPaymentSheet();
  }

  async createApplePay() {
    const {paymentIntent} = await this.http.post<{
      paymentIntent: string;
    }>(environment.api + 'payment-sheet', {
      user_id: this.customer_stripe_id,
      amount: 1099,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);

    await Stripe.createApplePay({
      paymentIntentClientSecret: paymentIntent,
      paymentSummaryItems: [{
        label: 'Product Name',
        amount: 1099.00
      }],
      merchantIdentifier: 'merchant.com.getcapacitor.stripe',
      countryCode: 'US',
      currency: 'USD',
    });
  }

  presentApplePay() {
    Stripe.presentApplePay();
  }

  async createGooglePay() {
    const {paymentIntent} = await this.http.post<{
      paymentIntent: string;
    }>(environment.api + 'payment-sheet', {}).pipe(first()).toPromise(Promise);

    await Stripe.createGooglePay({
      paymentIntentClientSecret: paymentIntent,
    });
  }

  presentGooglePay() {
    Stripe.presentGooglePay();
  }

  async checkOnboarding(email) {
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + '/retrieve-account', {email: email}).pipe(first()).toPromise(Promise);
    return this.response;
  }

  async getNecessaryFunds(price, targetUser) {
    let necessaryFunds = price *= 100;
    necessaryFunds = Math.trunc(necessaryFunds);
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + 'topup', {
      user_id: targetUser,
      amount: necessaryFunds,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);
    console.log(this.response)
    return this.response.resp;
  }

  async sendUserPayment(price, targetUser) {
    let necessaryFunds = price *= 100;
    necessaryFunds = Math.trunc(necessaryFunds);
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + 'payuser', {
      user_id: targetUser.stripe_account_id,
      amount: necessaryFunds,
      currency: 'USD'
    }).pipe(first()).toPromise(Promise);
    return this.response;
  }

  async getAccountBalance() {
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + 'balance', {user_id: this.account_stripe_id}).pipe(first()).toPromise(Promise);
    return this.response.balance;
  }

  async refundTransaction(paymentIntent) {
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + 'refund', {paymentIntent: paymentIntent}).pipe(first()).toPromise(Promise);
    return this.response.resp;
  }

  async completeOnboarding(stripeAccountId) {
    this.response = await this.http.post<{
      response: any;
    }>(environment.api + 'complete-onboarding', {
      stripeAccountId: stripeAccountId,
      email: this.customer_email
    }).pipe(first()).toPromise(Promise);
    return this.response;
  }

  async createNotification(titles, message, token) {
    // const body = {
    //   notification: {
    //     title: titles,
    //     body: message
    //   },
    //   to: token.replace(/['"]+/g, '')
    // };
    // this.response = await this.http.post<{
    //   response: any;
    // }>('https://fcm.googleapis.com/fcm/send', body).pipe(first()).toPromise(Promise);
    // return this.response;



    // const headers = new HttpHeaders({
    //   "Content-Type": "application/json",
    //   "Authorization": "key=AAAAnyxC884:APA91bH-isUU8RiNM6uPBoXsSt9KfXCuGEgj0bZ13-kd7vfjck9mRmDW-rywLFW8CSVuNkTprkQ7xyQtzc5Eqlf65sUXcA2TzmEvB9EH0XIT7JhfPFiCRDXbpQrXT5Df3gPY5yVwjv5p"
    // });
    
    // const notification = {
    //   notification: {
    //     title: titles,
    //     body: message
    //   },
    //   to: token.replace(/['"]+/g, '')
    // };
    
    // console.log(notification);


    // const url = 'https://fcm.googleapis.com/fcm/send';

    // this.http.post(url, notification, { headers }).subscribe(
    //   (response) => {
    //     console.log('Notification sent:', response);
    //   },
    //   (error) => {
    //     console.error('Notification error:', error);
    //   }
    // );
    
    // await fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    //   .then(response => {
    //     // Handle the response from FCM
    //     console.log('Notification Send successfully');
    //   })
    //   .catch(error => {
    //     // Handle the error
    //     console.error('Error Sennding Notification:', error);
    //   });

    this.sendGetRequestWithParams(titles, message, token)

  }

  sendGetRequestWithParams(titles, message, token) {
    const apiUrl = 'https://tapnet.tv/api/fcm.php'; // Replace with your PHP API URL

    const params = new HttpParams()
      .set('title', titles)
      .set('body', message)
      .set('token', token);

    this.http.get(apiUrl, { params }).subscribe(
      (response) => {
        console.log('Response:', response);
        // Handle the response here
      },
      (error) => {
        console.error('Error:', error);
        // Handle the error here
      }
    );
  }

  async createTopicNotification(titles, message, topic) {
    const body = {
      notification: {
        title: titles,
        body: message
      },
      to: topic
    };
    this.response = await this.http.post<{
      response: any;
    }>('https://fcm.googleapis.com/fcm/send', body).pipe(first()).toPromise(Promise);
    return this.response;
  }

}
