import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {switchMap, map, debounceTime, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';
import * as firebase from 'firebase';
import {FcmService} from './fcm.service';
import {Chat, ChatMessage} from '../pages/Models/chat.model';
import {firestore} from 'firebase/app';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {Post} from '../shared/models/post.model';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  profileUrl?: string;
}

export interface UserComments {
  userId: string;
  email: string;
  displayName: string;
  profileUrl?: string;
}

export interface Message {
  createdAt: firebase.firestore.FieldValue;
  id: string;
  from: string;
  msg: string;
  fromName: string;
  myMsg: boolean;
}

export interface Comments {
  createdAt: firebase.firestore.FieldValue;
  userProfile: string;
  userName: string;
  userId: string;
  commentReply: any;
}

export interface CommentsReplies {
  createdAt: firebase.firestore.FieldValue;
  userProfile: string;
  userName: string;
  userId: string;
  commentId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentUser: User = null;
  usersCommentsReplies: Observable<CommentsReplies[]>;
  allReplyes: Observable<CommentsReplies[]>;
  replyesByEachComments: CommentsReplies[] = [];

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private http: HttpClient, private fcmService: FcmService) {
    this.afAuth.onAuthStateChanged((user) => {
      this.currentUser = user;
      console.log("User from service", this.currentUser);
    });
  }

  addChat(recipient, sender) {
    const chatActions$ = this.afs.collection('chats', ref => {
      return ref
        .where(`users.${recipient.uid}`, '==', true)
        .where(`users.${sender.uid}`, '==', true);
    }).snapshotChanges();
    const chats$ = this.mapChats(chatActions$);

    chats$.subscribe(chats => {
      if (chats.length === 0) {
        const newChat: Chat = {
          createdByUserId: sender.uid,
          recipientId: recipient.uid,
          recipientName: recipient.displayName,
          recipientPhotoUrl: sender.profileUrl ? sender.profileUrl : sender.photoURL,
          senderName: sender.displayName,
          senderPhotoUrl: sender.profileUrl ? sender.profileUrl : sender.photoURL,

          messages: [],

          users: {
            [recipient.uid]: true,
            [sender.uid]: true,
          }

        };
        this.afs.collection('chats').add(newChat);
      }
    });
  }

  private mapChats(posts$: Observable<DocumentChangeAction<any>[]>): Observable<Chat[]> {
    return posts$.pipe(
      debounceTime(300),
      map(actions => {
        return actions.map((action: any) => {
          return {id: action.payload.doc.id, ...action.payload.doc.data()} as Chat;
        }).map(chat => {
          if (chat.messages.length > 0) {
            const lastMessage = chat.messages[chat.messages.length - 1];
            chat.lastMessage = {
              text: lastMessage.text,
              createdAt: (lastMessage.createdAt as firestore.Timestamp).toDate(),
            };
          } else {
            chat.lastMessage = {
              text: '',
              createdAt: null,
            };
          }

          return chat;
        });
      }),
    );
  }

  getChats(currentUserId: string): Observable<Chat[]> {
    const messages$ = this.afs.collection('chats', ref => ref.where('users.' + currentUserId, '==', true)).snapshotChanges();
    return this.mapChats(messages$);
  }

  async sendMessage(chatId: string, currentUserId: string, text: string) {
    try {
      const chatDocument = await this.afs.doc(`chats/${chatId}`).get().toPromise();
      const chat: Chat = chatDocument.data() as Chat;

      const newMessage: ChatMessage = {
        createdAt: new Date(),
        text,
        unread: true,
        userId: currentUserId,
      };
      chat.messages.push(newMessage);

      return await this.afs.collection('chats').doc(chatId).update({messages: chat.messages});
    } catch (error) {
      console.error('ChatService -> sendMessage -> error', error);
    }
  }

  readMessages(chatId: string, currentUserId: string): Observable<Chat> {
    const chat$: Observable<Chat> = this.afs.doc(`chats/${chatId}`).valueChanges() as Observable<Chat>;
    chat$.pipe(
      tap((chat: Chat) => {
        const messages: ChatMessage[] = chat.messages.filter((message: ChatMessage) => {
          return message.userId !== currentUserId;
        }).map((message: ChatMessage) => {
          message.unread = false;
          return message;
        });

        if (messages.length > 0) {
          this.afs.doc(`chats/${chatId}`).update({messages});
        }
      })
    );
    return chat$.pipe(
      map(chat => {
        chat.messages = chat.messages.map(message => {
          message.createdAt = (message.createdAt as firestore.Timestamp).toDate();
          return message;
        });
        return chat;
      })
    );
  }

  getChatMessages(uid, o_uid) {
    let users = [];
    return this.getUsers().pipe(
      switchMap(res => {
        users = res;
        return this.afs.collection('chats').doc(uid).collection(o_uid, ref => ref.orderBy('createdAt')).valueChanges({idField: 'id'}) as Observable<Message[]>;
      }),
      map(messages => {
        // Get the real name for each user
        for (let m of messages) {
          m.fromName = this.getUserForMsg(m.from, users);
          m.myMsg = this.currentUser.uid === m.from;
          m.createdAt = m.createdAt
        }
        return messages
      })
    )
  }

  getFollow(uid, o_uid) {
    let users = [];
    return this.getUsers().pipe(
      switchMap(res => {
        users = res;
        return this.afs.collection('user_data').doc(uid).collection(o_uid, ref => ref.orderBy('followAt')).valueChanges({idField: 'id'}) as Observable<Message[]>;
      }),
      map(messages => {
        // Get the real name for each user
        for (let m of messages) {
          m.fromName = this.getUserForMsg(m.from, users);
          m.myMsg = this.currentUser.uid === m.from;
          m.createdAt = m.createdAt
        }
        return messages
      })
    )
  }

  getComments(postId, comments, type) {
    let users = [];
    return this.getUsersComments().pipe(
      switchMap(res => {
        users = res;
        return this.afs.collection(type).doc(postId).collection(comments, ref => ref.orderBy('dateTime')).valueChanges() as Observable<Comments[]>;
      }),
      map(messages => {
        // Get the real name for each user
        for (let m of messages) {
          m.userName = this.getUserForMsg(m.userId, users);
          m.userProfile = this.getUserProfile(m.userId, users);
          m.createdAt = m.createdAt;
          m.commentReply = this.getCommentsReplies(postId, comments, m, type, "commentReplies");
        }
        return messages
      })
    )

  }

  getCommentsReplies(postId, comments, m, type, innercollection) {
    let users = [];
    return this.getUsersComments().pipe(
      switchMap(res => {
        users = res;
        return this.afs.collection(type).doc(postId).collection(comments).doc(m.id).collection(innercollection, ref => ref.orderBy('dateTime')).valueChanges() as Observable<CommentsReplies[]>;
      }),
      map(messages => {
        // Get the real name for each user
        for (let m of messages) {
          m.userName = this.getUserForMsg(m.userId, users);
          m.userProfile = this.getUserProfile(m.userId, users);
          m.createdAt = m.createdAt

        }
        return messages
      })
    )

  }

  private getUsers() {
    return this.afs.collection('user_data').valueChanges({idField: 'uid'}) as Observable<User[]>;
  }

  private getUsersComments() {
    return this.afs.collection('user_data').valueChanges({idField: 'userId'}) as Observable<UserComments[]>;
  }


  private getUserForMsg(msgFromId, users: User[]): string {
    for (let usr of users) {
      if (usr.uid == msgFromId) {
        return usr.displayName;
      }
    }
    return 'Deleted';
  }

  private getUserProfile(msgFromId, users: User[]): string {
    for (let usr of users) {
      if (usr.uid == msgFromId) {
        return usr.profileUrl;
      }
    }
    return 'Deleted';
  }

  PushNotificationFCM(data, url) {
    let token = "AAAAnyxC884:APA91bH-isUU8RiNM6uPBoXsSt9KfXCuGEgj0bZ13-kd7vfjck9mRmDW-rywLFW8CSVuNkTprkQ7xyQtzc5Eqlf65sUXcA2TzmEvB9EH0XIT7JhfPFiCRDXbpQrXT5Df3gPY5yVwjv5p";
    let FCMToken = token;
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeaderFCM(headers, FCMToken);
    return this.http
      .post(url, JSON.stringify(data), {headers: headers})
      .pipe(map(response => {
        let res = response
        console.log('response', res);
        return res;
      }))

  }

  createAuthorizationHeaderFCM(headers: HttpHeaders, FCMToken) {
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `key=${FCMToken}`);
    return headers;
  }

  getTokenFCM() {
    let FCMToken = localStorage.getItem('fcmToken');
    return FCMToken;
  }

  getPost(postId: string): Observable<Post> {
    return this.afs.doc(`tiktokPosts/${postId}`).valueChanges() as Observable<Post>;
  }
}
