import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable, throwError} from "rxjs";
import { ShareService } from '../services/share.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
  // private authService: AuthService
  constructor(private shareService: ShareService, private loadingController: LoadingController) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log(request);
    if (request.url.includes('https://spotify23.p.rapidapi.com/')) {
      request = request.clone({
          headers: request.headers.set('x-rapidapi-host', 'spotify23.p.rapidapi.com')
        },
      );
      request = request.clone({
          headers: request.headers.set('x-rapidapi-key', '7fc42e4037msh1ec594938dc8d56p1abe9bjsn65532579f7d7')
        },
      );
    }
    if (request.url.includes('https://fcm.googleapis.com/fcm/send')) {
      request = request.clone({
          headers: request.headers.set('Authorization', 'key=AAAAnyxC884:APA91bH-isUU8RiNM6uPBoXsSt9KfXCuGEgj0bZ13-kd7vfjck9mRmDW-rywLFW8CSVuNkTprkQ7xyQtzc5Eqlf65sUXcA2TzmEvB9EH0XIT7JhfPFiCRDXbpQrXT5Df3gPY5yVwjv5p')
        },
      );
    }
    if (request.url === 'https://accounts.spotify.com/api/token') {
      const auth = btoa(localStorage.getItem('client_id') + ':' + localStorage.getItem('client_secret'));
      const token = auth;
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${token}`,
          },
        });
      }
    } else {
      const auth = localStorage.getItem('access_token_tap');
      // const auth = 'BQDkjN5fTub6EFUbaKTja8bYcBhOFgcFu0XS0BtQg-oEt1Gf7KVpBRn8c0L9OKAJ2KXUe8AsKtlvwzo4pJRXNQchkd_6PXAY5lBdVTtpCNZnZ3-S_LndzM1xNpvvQTLtp7bg6lAK63SAiEIrCfHJrOWCCAQ-0YplkXjSG-JPg2JGaN4TcQ66OFg0fLaXFlJMJI-lY36vVYWt0pURtWXBCIQNGgzbwdfyqsdKX9toJkNPOA';
      const token = auth;
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err)
        if (err.status === 401) {
          // this.authService.logout();
        }
        const error = err.error.message || err.statusText;
        this.shareService.presentToastMsg(error, 'warning');
        if(this.loadingController) {
          this.loadingController.dismiss();
        }
        return throwError(error);
      })
    );
  }
}
