import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { User } from '../pages/Models/User';
import { AppAccountInfo } from '../shared/models/app-account-info.model';
import { ShareService } from './share.service';
import * as firebase from 'firebase/app';
import { firestore } from 'firebase/app';

import { promise } from 'selenium-webdriver';
@Injectable()
export class UserService {
  private user: Observable<User[]>;
  private userCollections: AngularFirestoreCollection<User>;
  constructor(private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private shareService: ShareService
    ) {
    this.userCollections = this.afs.collection<User>('user_data');
    this.user = this.userCollections.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = data.uid;
          return { id, ...data };
        });
      })
    );
  }
  getItems(): Observable<User[]> {
    return this.user;
  }
  getItem(id: string): Observable<User> {
    return this.userCollections
      .doc<User>(id)
      .valueChanges()
      .pipe(
        take(1),
        map((user) => {
          user.uid = id;
          return user;
        })
      );
  }
  currentUserSync() {
    return this.afAuth.currentUser;
  }
  addItem(user: User): Promise<DocumentReference> {
    return this.userCollections.add(user);
  }
  updateItem(user: User): Promise<void> {
    return this.userCollections.doc(user.uid).update(user);
  }
  deleteItem(id: string): Promise<void> {
    return this.userCollections.doc(id).delete();
  }
  getAllAccounts(): Observable<User[]> {
    return this.afs.collection('user_data').valueChanges() as Observable<User[]>;
  }
  async getCurrentAccountInfo(): Promise<User> {
    try {
      const currentUser = await this.currentUser();
      const user = await this.afs.doc(`user_data/${currentUser.uid}`).get().toPromise();
      return user.data() as User;
    } catch (error) {
      throw new Error(error);
    }
  }
  async currentUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }
  async getAccountInfoById(uid: string): Promise<AppAccountInfo> {
    try {
      const accountInfoDocument = await this.afs.doc(`user_data/${uid}`).get().toPromise();
      return accountInfoDocument.data() as AppAccountInfo;
    } catch (error) {
      return null;
    }
  }
   isFollowing(currentUserId: string, postedByUserId: string):Promise<boolean> {

   return;


   }
  async follow(followerUserId: string, followeeUserId: string) {
    try {
      const accountInfo: AppAccountInfo = (await this.afs.doc(`accounts/${followeeUserId}`).get().toPromise()).data() as AppAccountInfo;
      const followers = { [followerUserId]: true, ...accountInfo.followers };
      await this.afs.doc('accounts/' + followeeUserId).update({ followers });

      const following = { [followeeUserId]: true, ...accountInfo.followers };
      await this.afs.doc('accounts/' + followerUserId).update({ following });
    } catch (error) {
      console.error('AccountService -> follow -> error', error);
    }
  }
  async UnFollowing(userId,currentUser) {
    if(currentUser.uid == userId){
      return;
    }
    let followingDetail = this.mapDataUnFollow(userId);
    let followersDetail = this.mapfollowersData(currentUser);
    this.afs.doc('user_data/' + currentUser.uid + '/following/' + followingDetail.userId).delete();
    this.afs.doc('user_data/' + followingDetail.userId + '/followers/' + followersDetail.userId).delete();
    this.afs.collection('user_data').doc(currentUser.uid).update({totalFollowing: firestore.FieldValue.increment(-1),
                  });
                this.afs.collection('user_data').doc(followingDetail.userId).update({
                    totalFollowers: firestore.FieldValue.increment(-1),
                  });


  }
  async  following(userId,currentUser):Promise<boolean> {
    debugger
    if(currentUser.uid == userId){
      return;
    }
    let followingDetail = this.mapDataUnFollow(userId);
    let followersDetail = this.mapfollowersData(currentUser);
    this.shareService
      .following('user_data', currentUser.uid, 'following',followingDetail)
      .then(
        () => {
          this.shareService
            .followers(
              'user_data',
              followingDetail,
              'followers',
              followersDetail
            )
            .then(
              () => {

                this.afs
                  .collection('user_data')
                  .doc(currentUser.uid)
                  .update({
                    totalFollowing: firestore.FieldValue.increment(1),
                  });
                this.afs
                  .collection('user_data')
                  .doc(followingDetail.userId)
                  .update({
                    totalFollowers: firestore.FieldValue.increment(1),
                  });
              return true;
              },
              (err) => {
                console.log('Error', err);
                return false;
              }
            );
        },
        (err) => {
          console.log('Error', err);
          return false;
        }
      );
  }

  mapDataUnFollow(userId) {
    let following = {
      userId: userId,
      followAt: new Date(),

    };
    return following;
  }
  mapfollowersData(userId) {
    let followers = {
      userId: userId.uid,
      followAt: new Date(),

    };
    return followers;
  }

  async unfollow(followerUserId: string, followeeUserId: string) {
    try {
      const followedAccountInfo = (await this.afs.doc(`accounts/${followeeUserId}`).get().toPromise()).data() as AppAccountInfo;
      delete followedAccountInfo.followers[followerUserId];
      await this.afs.doc('accounts/' + followeeUserId).update({ followers: followedAccountInfo.followers });

      const currentAccountInfo = (await this.afs.doc(`accounts/${followeeUserId}`).get().toPromise()).data() as AppAccountInfo;
      delete currentAccountInfo.followers[followeeUserId];
      await this.afs.doc('accounts/' + followeeUserId).update({ following: currentAccountInfo.followers });

    } catch (error) {
      console.error('AccountService -> unfollow -> error', error);
    }
  }
}
