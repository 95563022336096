import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Message {
  createdAt: Date,
  message: string,
  sentBy: string,
  read: boolean
}

export interface InquiryDM {
  messages: Message[],
  id: string,
  gigOwner: string,
  inquirer: string,
  gigId: string,
  type: string,
  package: any,
  previousOrders: any[],
  notifOwner: number,
  notifInquirer: number
}

@Injectable({
  providedIn: 'root'
})

export class ServiceMessagesService {


  messages: Message[];

  constructor(private afs: AngularFirestore) { }

  async startNewInquiry(inquirer, gigOwner, gigId, type, selectedPackage) {
    let newDM: InquiryDM = {
      messages: [],
      id: gigOwner.uid + inquirer.uid + gigId,
      gigOwner: gigOwner.uid,
      inquirer: inquirer.uid,
      gigId: gigId,
      type: type,
      package: selectedPackage,
      previousOrders: [],
      notifOwner: 0,
      notifInquirer: 0,
    }

    let msg = '';

    if(type === 'Business'){
      if(inquirer.accountType === 'Business'){
        msg = "Hello! Our business would like to work with yours!"
      }else{
        msg = "Hello! I would like to work with your business!"
      }
    }else{
      if(inquirer.accountType === 'Business'){
        msg = "Hello! Our business would like to work with you!"
      }else{
        msg = "Hello! I would like to work with you!"
      }
    }

    let firstMsg: Message = {
      createdAt: new Date(),
      message: msg,
      sentBy: inquirer.uid,
      read: false
    }

    newDM.messages.push(firstMsg);

    this.afs
      .collection('inquiries')
      .doc(newDM.id)
      .set(newDM)
  }

  async addMessage(msg, id, sentUser) {
    try {
      const chatDocument = await this.afs.doc(`inquiries/${id}`).get().toPromise();
      const dm: InquiryDM = chatDocument.data() as InquiryDM;

      let newMessage: Message = {
        createdAt: new Date(),
        message: msg,
        sentBy: sentUser.uid,
        read: false
      };

      dm.messages.push(newMessage);

      return await this.afs.collection('inquiries').doc(id).update({ messages: dm.messages });
    } catch (error) {
      console.error('ChatService -> sendMessage -> error', error);
    }
  }

  async addOffer(offer, id){
    try {
      const chatDocument = await this.afs.doc(`inquiries/${id}`).get().toPromise();
      const dm: InquiryDM = chatDocument.data() as InquiryDM;

      dm.messages.push(offer);

      return await this.afs.collection('inquiries').doc(id).update({ messages: dm.messages });
    } catch (error){
      console.error('ChatService -> sendMessage -> error', error);
    }

  }

  async addRevision(revision, id){
    try{
      const chatDocument = await this.afs.doc(`inquiries/${id}`).get().toPromise();
      const dm: InquiryDM = chatDocument.data() as InquiryDM;

      dm.messages.push(revision);

      return await this.afs.collection('inquiries').doc(id).update({ messages: dm.messages });
    }catch (error){
      console.error('ChatService -> sendMessage -> error', error);
    }
  }

  async updateOffer(offer, i, id){
    try {
      const chatDocument = await this.afs.doc(`inquiries/${id}`).get().toPromise();
      const dm: InquiryDM = chatDocument.data() as InquiryDM;

      dm.messages[i] = offer;
      console.log(offer)
      console.log(dm.messages)
      return await this.afs.collection('inquiries').doc(id).update({ messages: dm.messages });
    } catch (error){
      console.error('ChatService -> sendMessage -> error', error);
    }
  }

  async addPreviousOrder(order, id){
    try {
      const chatDocument = await this.afs.doc(`inquiries/${id}`).get().toPromise();
      const dm: InquiryDM = chatDocument.data() as InquiryDM;

      dm.previousOrders.push(order);

      return await this.afs.collection('inquiries').doc(id).update({ messages: dm.messages });
    } catch (error){
      console.error('ChatService -> sendMessage -> error', error);
    }
  }

  getChatMessages(id){

    return this.afs.collection('inquiries').doc(id).valueChanges() as Observable<InquiryDM>;
  }
}
