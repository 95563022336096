import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

export interface User {
  uid: string;
  email: string;
  displayName:string;
  profileUrl?:string;
}
export interface Message {
  createdAt: firebase.firestore.FieldValue;
  id: string;
  from: string;
  msg: string;
  fromName: string;
  myMsg: boolean;
}
export interface Follower{
  userId: string,
  followAt:  Date,

}
@Injectable({
  providedIn: 'root'
})
export class ShareService {
item:any;
mySelCategory = 'photos';
myforandfollowing = 'Foryou';
currentUser: User = null;
  constructor(public toastController: ToastController,private firestore: AngularFirestore,private afAuth: AngularFireAuth) {
    this.afAuth.onAuthStateChanged((user) => {
      this.currentUser = user;
    });
   }
  async presentToast(value) {
    const toast = await this.toastController.create({
      message: value,
      duration: 2000,
      color: 'success',
    });
    toast.present();
  }
  async presentToastMsg(value,color) {
    const toast = await this.toastController.create({
      message: value,
      duration: 2000,
      color: color,
    });
    toast.present();
  }
  addRecord(collectionName,record) {
    console.log('recordrecord', record)
    console.log('collectionName', collectionName)
    return this.firestore.collection(collectionName).doc(record.uid).set(record);

  }
  following(collectionName,userId,innerCollection,innerRecord) {

    return this.firestore.collection(collectionName).doc(userId).collection(innerCollection).doc(innerRecord.userId).set(innerRecord);
  }
  followers(collectionName,user,innerCollection,innerRecord) {
    return this.firestore.collection(collectionName).doc(user.userId).collection(innerCollection).doc(innerRecord.userId).set(innerRecord);
  }
  readFollowers(collectionName,user,innerCollection,innerRecord) {
    return this.firestore.collection(collectionName).doc(user.userId).collection(innerCollection).snapshotChanges();
  }
  readFollower(collectionName,userId,innerCollection,followerID) {


    const follower=this.firestore.collection(collectionName).doc(followerID).collection<Follower>(innerCollection).snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();

         return {...data} as Follower
        });
      })
    );


  return follower;

  }

  readFollowing(collectionName,userId,innerCollection) {
    return this.firestore.collection(collectionName).doc(userId).collection(innerCollection).snapshotChanges();
  }
  readRecord(collectionName) {
    return this.firestore.collection(collectionName).snapshotChanges();
  }

  updateRecord(collectionName,recordID, record) {
    this.firestore.doc(collectionName + '/' + recordID).update(record);
  }

  deleteRecord(collectionName,record_id) {
    this.firestore.doc(collectionName + '/' + record_id).delete();
  }
createOject(item){
this.item = item;
}
readObject() {
return this.item;
}
makeRandom(lengthOfCode: number, possible: string) {
  let text = "";
  for (let i = 0; i < lengthOfCode; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
    return text;
}
getUserById(collections,id,subCollections) {
  let users = [];
  return this.getUsers().pipe(
    switchMap(res => {
      users = res;
      return this.firestore.collection(collections).doc(id).collection(subCollections, ref => ref.orderBy('createdAt')).valueChanges() as Observable<Message[]>;
    }),
    map(messages => {
      // Get the real name for each user
      for (let m of messages) {
        m.fromName = this.getUserForMsg(m.from, users);
        m.myMsg = this.currentUser.uid === m.from;
        m.createdAt=m.createdAt
      }
      return messages
    })
  )
}
private getUsers() {
  return this.firestore.collection('user_data').valueChanges({ idField: 'uid' }) as Observable<User[]>;
}
private getUserForMsg(msgFromId, users: User[]): string {
  for (let usr of users) {
    if (usr.uid == msgFromId) {
      return usr.displayName;
    }
  }
  return 'Deleted';
}
async presentLoading(loading) {
  return await loading.present();
}
}
