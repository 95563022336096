import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PostService {
 userPosts

    constructor(
        private afs: AngularFirestore
    ) {
    }

    createNewId() {

        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let autoId = ''
        for (let i = 0; i < 20; i++) {
            autoId += chars.charAt(Math.floor(Math.random() * chars.length))
        }

        return autoId;

    }

    async checkExistingId(id, collection){
      let document = await this.afs
        .collection(collection)
        .doc(id)
        .get()
        .toPromise()
        return document.data();
    }

    async checkExistingId2(id1, collection1, id2, collection2){
      let document = await this.afs
        .collection(collection1)
        .doc(id1)
        .collection(collection2)
        .doc(id2)
        .get()
        .toPromise()
        return document.data();
    }

}
