import {Directive,ElementRef, HostListener} from '@angular/core'

@Directive({
	selector:'[autofocus]'
})

// implements AfterViewInit
export class AutoFocus{

	// constructor(
	// 	private elementRef: ElementRef
	// ){}

	// ngAfterViewInit(){
	// 	this.elementRef.nativeElement.focus();
	// }

	constructor(private el: ElementRef) {}

  @HostListener('click') onClick() {
    const inputElement: HTMLInputElement = this.el.nativeElement.querySelector('input');
    
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
